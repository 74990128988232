import React from "react"
import { css, jsx } from "@emotion/core"
import { graphql } from 'gatsby'

import IndustryLayout from "../../components/layouts/industryLayout"

const breakpoints = [980]
const mq = breakpoints.map(
    bp => `@media (min-width: ${bp}px)`
)

const TCell = ({ width, mWidth, styles, children }) => (
  <div css={{
    textAlign: `center`,
    boxSizing: `border-box`,
    flexGrow: `1`,
    width: mWidth,
    padding: `0.8em 1.2em`,
    overflow: `hidden`,
    listStyle: `none`,
    border: `solid 3px white`,
    background: `rgba(112, 128, 144, 0.2)`,
    [mq[0]]: {
      width: width,
      marginBottom: `0`,
    },
    ...styles
  }}>
    {children}
  </div>
)


const MembershipDirectoryPage = ({data}) => {
  const businessMembersTable = data.allBusinessMembersCsv.nodes.map(i =>
    <>
      <TCell width="33%" mWidth="100%">
        <h3>{i.name}</h3>
        <p>{i.phone}</p>
        <div>
          <span>{i.street}</span><br />
          <span>{i.city}, {i.state} {i.zip}</span>
        </div>
      </TCell>
    </>
  )

  const personalMembersTable = data.allPersonalMembersCsv.nodes.map(i => 
    <TCell width="33%" mWidth="100%">{i.name}</TCell>
  )

  return (
    <IndustryLayout pageTitle="Membership Directory">
      <h2>The 2023 Membership Directory will be posted soon!</h2>
      {/** 
      <h2>Business Members</h2>
      <div css={{
        display: `flex`,
        flexWrap: `wrap`,
        margin: `0 0 3rem 0`,
        padding: `0`,
      }}>
        {businessMembersTable}
    </div>
    <h2>Individual Members</h2>
    <div css={{
      display: `flex`,
      flexWrap: `wrap`,
      margin: `0 0 3rem 0`,
      padding: `0`,
    }}>
      {personalMembersTable}
    </div>
    */}
    </IndustryLayout>
  )
}


export const query = graphql`
  query MembersQuery {
    allBusinessMembersCsv {
      nodes {
        name
        street
        city
        state
        zip
        phone
      }
    }
    allPersonalMembersCsv {
      nodes {
        name
      }  
    }
  }
`




export default MembershipDirectoryPage
